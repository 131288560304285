import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Webcam from 'react-webcam'
import {Image, Transformation} from 'cloudinary-react';
import superagent from 'superagent';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import cloudinary from '../lib/cloudinary';
import defaults from '../lib/appdefaults';

class Home extends Component {
  state = {
    picUri: '',
    transformedPicUrl: '',
    picTaken: false,
    picTransformed: false,
    picComment: '',
    sharePicUrl: '',
    weatherString: '',
    facingMode: 'user'
  }

  setWebcamRef = webcam => {
    this.webcam = webcam;
  };

  async componentWillMount() {
    await superagent.get(window.location.protocol + '//' + window.location.host + '/w.php')
      .set('content-type','application/json')
      .then((res) => this.setState({ weatherString: res.text}))
      .catch((err) => console.log(err));
  }

  takePhoto() {
    const dataUri = this.webcam.getScreenshot();
    
    this.uploadToCloudinary(dataUri);
    this.setState({
      picUri: dataUri,
      picTaken: true
    });
  }

  uploadToCloudinary = (dataUri) => {
    superagent.post(cloudinary.url)
      .send({
        'file': dataUri,
        'upload_preset': cloudinary.upload_preset,
      })
      .then((res) => {
        let shareUrl = window.location.protocol + '//' + window.location.host + '/i.php?u=' + res.body.public_id;
        this.setState({
          picTransformed: true,
          transformedPicUrl: res.body.secure_url,
          transformedPicPublicID: res.body.public_id,
          sharePicUrl: shareUrl,
        });
      })
      .catch((err) => console.log(err))
  }

  restart = () => {
    this.setState({
      picUri: '',
      transformedPicUrl: '',
      picTaken: false,
      picTransformed: false,
      sharePicUrl: '',
    });
  }

  onComment = (value) => {
    this.setState({ picComment: value })
  }

  render() {
    if(this.state.picTaken) {
      if(this.state.picTransformed) {
        return(
          <section className="section">
            <div className="container has-text-centered">
              <Image cloudName="mayway-werbung-pr-gmbh" publicId={this.state.transformedPicPublicID}>
                <Transformation overlay={"text:arial_60:" + this.state.weatherString} gravity="south" y="15" effect="colorize" color="#FFFFFF" />
              </Image>
              <br/>
              <br/>
              <h1 className="title is-size-5 has-text-centered">Dein Foto ist bereit zum Teilen!</h1>
              <textarea id="comment-box" cols="30" rows="2" maxLength="140" placeholder="Beschreibe dein Foto oder schreib einen lustigen Kommentar" className="textarea" onChange={(e) => this.onComment(e.nativeEvent.srcElement.value) }></textarea>
            </div>

            <br/>

            <div className="container has-text-centered">
              <div className="columns is-mobile is-multiline">
                <div className="column is-3">
                  <FacebookShareButton url={this.state.sharePicUrl} quote={this.state.picComment + " " + defaults.hashtags_string}>
                    <FacebookIcon size={48} />
                  </FacebookShareButton>
                </div>
                <div className="column is-3">
                  <TwitterShareButton url={this.state.sharePicUrl} title={this.state.picComment} hashtags={defaults.hashtags}>
                    <TwitterIcon size={48} />
                  </TwitterShareButton>
                </div>
                <div className="column is-3">
                  <WhatsappShareButton url={this.state.sharePicUrl} title={this.state.picComment}>
                    <WhatsappIcon size={48} />
                  </WhatsappShareButton>
                </div>
                <div className="column is-3">
                  <EmailShareButton url={this.state.sharePicUrl} subject={defaults.title} body={this.state.picComment}>
                    <EmailIcon size={48} />
                  </EmailShareButton>
                </div>
              </div>
            </div>

            <br/>

            <div className="container has-text-centered">
              <button className="button is-square" onClick={() => this.restart()}>
                <i className="icons icon-refresh">&nbsp;</i>
                nochmal beginnen
              </button>
            </div>
          </section>
        )
      }
      else {
        return(
          <section className="section">
            <div className="container has-text-centered">
              <div>
                <br/>
                <br/>
                <br/>
                <i className="icons icon-hourglass spinner"></i>
                <br/>
                <br/>
              </div>
              <h1 className="title">Snap! Foto gemacht! :)</h1>
              <br/>
              <h2 className="subtitle">Wir bereiten jetzt dein Bild vor, bitte einen Moment Geduld...</h2>
            </div>
          </section>
        )
      }

    }
    else { // show image capture
      return (
        <section className="section">
          <div className="container has-text-centered">

            <Webcam
              audio={false}
              height={1080}
              screenshotFormat="image/jpeg"
              width={1080}
              ref={this.setWebcamRef}
              minScreenshotWidth={1080}
              minScreenshotHeight={1080}
              mirrored={false}
              videoConstraints={{
                width: 1080,
                height: 1080,
                facingMode: "user"
              }}
            />

            <br/>
            
            <button className="button is-rounded is-success" onClick={() => { this.takePhoto()}}>
              <i className="icons icon-camera"></i>
            </button>
            &nbsp;
            <Link className="button is-rounded" to="/environment">
              <i className="icons icon-refresh"></i>
            </Link>
            <br/>
            
            <br/>
            <h1 className="title is-size-4">Schieß' los!</h1>
            <p>
              Jetzt Foto aufnehmen und im nächsten Schritt mit eigener Nachricht auf Facebook, Twitter oder WhatsApp teilen!
            </p>
          </div>
        </section>
      );
    }

  }
}

export default Home;
