import React from 'react';
import './App.sass';

import 'bulma/bulma.sass';
import Layout from "./components/Layout/Layout";

function App() {
  return (
    <Layout/>
  );
}

export default App;
