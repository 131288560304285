import React, {Component} from 'react';
import Home from '../Home';
import HomeEnvo from '../HomeEnvironment';
import {BrowserRouter, Route} from "react-router-dom";

class Layout extends Component {
  render() {
    return (
      <div id="device">
        <div id="content">
          <BrowserRouter>
            <Route path="/" exact component={Home}/>
            <Route path="/environment" exact component={HomeEnvo}/>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default Layout;
